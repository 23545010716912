<template>
    <div class="content-editor">
        <content-editor-link-input
            ref="linkEditor"
            style="z-index: 1001"
            :link-range="linkRange"
            :link-bounds="linkBounds"
            @link="insertCustomLink"
            @escape="focus"
        />
        <div ref="editor"></div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ProvideReactive, Watch } from '@/utils/decorators';
import Quill, {
    Sources,
    DeltaStatic,
    QuillOptionsStatic,
    RangeStatic,
    BoundsStatic
} from 'quill';
import Delta from 'quill-delta';

import ContentEditorLinkInput from './ContentEditorLinkInput.vue';

import { debounce, stripEmptyLinks } from '@/utils/helpers';

import FirstPersonError from './blots/FirstPersonError';
import VideoPlaceholder from './blots/VideoPlaceholder';

Quill.register(FirstPersonError, true);
Quill.register(VideoPlaceholder);

const REG_EXP = {
    URL: /https?:\/\/[^\s]+/,
    TRAILING_DOT: /\.+$/
};

const ContentEditorProps = Vue.extend({
    props: {
        placeholder: {
            type: String,
            default() {
                return '';
            }
        },
        value: {
            type: String,
            default() {
                return '';
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        autoLinks: {
            type: Boolean,
            default() {
                return true;
            }
        },
        noFirstPerson: {
            type: Boolean,
            default() {
                return true;
            }
        },
        formats: {
            type: Array<string>,
            default() {
                return ['link', 'firstPersonError'];
            }
        },
        toolbar: {
            type: Array<Array<string | object>>,
            default() {
                return [['link']];
            }
        },
        addVideoPlaceholder: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        ContentEditorLinkInput
    }
})
export default class ContentEditor extends ContentEditorProps {
    $refs!: {
        editor: HTMLDivElement;
        linkEditor: InstanceType<typeof ContentEditorLinkInput>;
    };

    get isEmpty() {
        return this.quill ? this.quill.editor.isBlank() : true;
    }

    @Watch('value')
    onValueChange(value: string) {
        if (this.quill) {
            // Accept initial value only
            // And continue with user input afterwards
            if (this.isEmpty && value) {
                const delta = this.quill.clipboard.convert(value);

                this.quill.setContents(delta, 'silent');

                this.content = this.getHtml();

                this.markFirstPerson();
                // NEVER EMIT FROM HERE!
                // THIS IS ONE WAY SOURCE->VALUE ONLY!
            }
        }
    }

    @Watch('disabled')
    onDisabled(value: boolean) {
        if (this.quill) {
            this.quill.enable(!value);
        }
    }

    quill: InstanceType<typeof Quill> | null = null;

    content = '';

    editorOptions: QuillOptionsStatic = {
        theme: 'bubble',
        formats: ['link', 'firstPersonError'],
        modules: {
            toolbar: [['link']],
            history: {
                userOnly: true
            },
            keyboard: {
                bindings: {}
            }
        },
        placeholder: this.placeholder,
        scrollingContainer: document.documentElement
    };

    @ProvideReactive()
    linkRange: RangeStatic | null = null;

    @ProvideReactive()
    linkBounds: BoundsStatic | null = null;

    mounted() {
        this.quill = new Quill(this.$refs.editor, this.getEditorConfig());

        this.quill
            .getModule('toolbar')
            .addHandler('link', this.handleToolbarLink);

        this.quill
            .getModule('toolbar')
            .addHandler('header', this.handleToolbarHeading);

        this.quill.clipboard.addMatcher(
            Node.ELEMENT_NODE,
            this.clipboardMatcher
        );

        this.quill.clipboard.addMatcher(Node.TEXT_NODE, this.autoLinksMatcher);

        if (this.disabled) {
            this.quill.enable(false);
        }

        setTimeout(() => {
            this.onReady();
        }, 250);
    }

    onReady() {
        if (this.quill) {
            this.quill.on('text-change', this.onTextChange.bind(this));

            this.quill.on(
                'selection-change',
                this.onSelectionChange.bind(this)
            );

            this.$emit('ready', this.quill);
        }
    }

    beforeDestroy() {
        this.quill = null;
    }

    getHtml() {
        return this.isEmpty ? '' : this.quill?.root.innerHTML || '';
    }

    onTextChange() {
        if (this.quill) {
            this.onInput();

            this.markFirstPerson();
        }
    }

    onInput() {
        if (this.quill) {
            this.content = stripEmptyLinks(this.getHtml());

            this.$emit('input', this.content);
        }
    }

    markFirstPerson() {
        if (this.noFirstPerson) {
            this.debouncedHighlight();
        }
    }

    onSelectionChange(
        range: RangeStatic | null,
        oldRange: RangeStatic | null,
        source: Sources
    ) {
        if (!range) {
            this.$emit('blur', this.quill);
        } else {
            this.$emit('focus', this.quill);
        }

        if (this.quill && range && source === 'user') {
            if (range.length === 0) {
                this.onEditorClick(range, oldRange);
            } else {
                this.onTextSelection(range);
            }
        }
    }

    onEditorClick(range: RangeStatic, oldRange: RangeStatic | null) {
        if (range !== oldRange) {
            this.$refs.linkEditor.hide();
        }

        if (this.quill) {
            const format = this.quill.getFormat(range);
            // check if cursor is on link.
            if (format.link) {
                const [blot, position] = this.quill.getLeaf(range.index);

                const text = blot.text ? blot.text : false;

                if (text) {
                    const blotIndex = this.quill.getIndex(blot);
                    if (blotIndex === range.index - text.length) {
                        // don't show popup if user clicks to the end of the link
                        return;
                    }
                    range.index -= position; // set cursor to whole link.

                    range.length = text.length;

                    this.linkRange = range;

                    this.linkBounds = this.quill.getBounds(
                        range.index,
                        range.length
                    );

                    this.$refs.linkEditor.show(text, format.link);
                }
            }
        }
    }

    onTextSelection(range: RangeStatic) {
        if (this.quill) {
            const delta = this.quill.getContents(range.index, range.length);

            const containsVideoPlaceholder =
                delta.filter(
                    op =>
                        typeof op.insert === 'object' &&
                        Object.hasOwn(op.insert, 'videoPlaceholder')
                ).length > 0;

            if (containsVideoPlaceholder) {
                this.quill.theme.tooltip.hide();
                return;
            }

            const preCh = this.quill.getText(range.index - 1, 1);
            const postCh = this.quill.getText(range.index + range.length, 1);

            if (
                (preCh === '' && this.isWhitespace(postCh)) || // first word in sentence
                (this.isWhitespace(preCh) && this.isWhitespace(postCh)) || // word with space around
                (this.isWhitespace(preCh) &&
                    (postCh === '.' || postCh === ',')) || // word followed by punctuation
                (preCh === '(' && postCh === ')') // word in parentheses
            ) {
                this.quill.theme.tooltip.show();
            }
        }
    }

    handleToolbarLink(value: boolean) {
        if (this.quill) {
            const range = this.quill.getSelection();

            if (value && range?.length === 0) {
                this.linkRange = range;

                this.linkBounds = this.quill.getBounds(range.index, 0);
            } else {
                if (range === null || range.length === 0) {
                    return;
                }

                const selectedText = this.quill.getText(
                    range.index,
                    range.length
                );

                this.linkRange = range;

                this.linkBounds = this.quill.getBounds(
                    range.index,
                    range.length
                );

                this.$refs.linkEditor.show(selectedText);
            }
        }
    }

    insertCustomLink(delta: Delta) {
        if (this.quill && delta) {
            this.quill.updateContents(delta as unknown as DeltaStatic, 'user');

            this.$nextTick(() => {
                this.$emit('input', this.getHtml());
            });
        }
    }

    debouncedHighlight = debounce(
        this.highlightInvalidFirstPersonWords.bind(this),
        500
    );

    highlightText(regEx: RegExp, unhighlight = false, flags = 'igm') {
        if (this.quill) {
            const content = this.quill.getText();

            const multiRegex = new RegExp(regEx.source, flags);

            let matches: RegExpExecArray | null = null;

            while ((matches = multiRegex.exec(content)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (matches.index === multiRegex.lastIndex) {
                    multiRegex.lastIndex++;
                }

                if (unhighlight) {
                    this.quill.formatText(
                        matches.index,
                        matches[0].length,
                        { firstPersonError: false },
                        'silent'
                    );
                } else {
                    this.quill.formatText(
                        matches.index,
                        matches[0].length,
                        { firstPersonError: true },
                        'silent'
                    );
                }

                // check the delta object for an embedded link to remove highlighting
                const delta = this.quill.getContents();

                // eslint-disable-next-line no-loop-func
                delta.forEach(op => {
                    if (op.attributes) {
                        const link = op.attributes.link;

                        const error = op.attributes.firstPersonError;

                        if (link && error && matches) {
                            this.quill?.formatText(
                                matches.index,
                                matches[0].length,
                                { firstPersonError: false },
                                'silent'
                            );
                        }
                    }
                });
            }
        }
    }

    highlightInvalidFirstPersonWords() {
        if (this.quill) {
            this.quill.formatText(
                0,
                this.quill.getLength(),
                { firstPersonError: false },
                'silent'
            );

            // format all first person words
            this.highlightText(
                /\b(?![^\w-])(i|me|my|mine|our|ours|we|us|you|your|yours)(?![\w-])\b/
            );

            // remove formatting from anything between dots (i.e, l.i.v.e)
            this.highlightText(
                /\b(?:(i|me|my|mine|our|ours|we|us|you|your|yours)\.\w+)\b/,
                true
            );
            this.highlightText(
                /\b(?:\w+\.(i|me|my|mine|our|ours|we|us|you|your|yours))\b/,
                true
            );

            // remove formatting from URLs
            this.highlightText(/https?:\/\/[^\s]+/, true);

            // remove formatting from Maine (ME), United States (US)
            this.highlightText(/\b(?:ME|US|MY)\b/, true, 'gm');

            // remove formatting from roman numeral I between parenthesis
            this.highlightText(/\(i\)/, true);

            // remove formatting from quoted sentences
            this.highlightText(
                /"(.|\n)+?"|\B'(.|\n)+'\B|“(.|\n)+?”|‘(.|\n)+?’/,
                true
            );
        }
    }

    isWhitespace(char: string) {
        const whitespaces = [' ', '\t', '\n'];

        return whitespaces.includes(char);
    }

    clipboardMatcher(_node: Node, delta: DeltaStatic) {
        return delta.compose(
            new Delta().retain(delta.length(), {
                firstPersonError: false
            }) as unknown as DeltaStatic
        );
    }

    autoLinksMatcher(node: { data?: string }, delta: DeltaStatic) {
        if (typeof node.data !== 'string') {
            return delta;
        }
        // add matcher regex for links, used in the text-change to autoformat.
        const matches = node.data.match(REG_EXP.URL);

        if (matches && matches.length > 0) {
            const ops = [];
            let str = node.data;
            matches.forEach(match => {
                // trim trailing dots
                match = match.replace(REG_EXP.TRAILING_DOT, '');
                const split = str.split(match);
                const beforeLink = split.shift();
                ops.push({ insert: beforeLink });
                ops.push({ insert: match, attributes: { link: match } });
                str = split.join(match);
            });
            ops.push({ insert: str });
            delta.ops = ops;
        }

        return delta;
    }

    handleToolbarHeading(headingLevel: number) {
        if (!this.quill) {
            return;
        }

        const range = this.quill.getSelection();
        // no text selected, ignore
        if (range === null || range.length === 0) {
            return;
        }

        this.quill.format('header', headingLevel, 'user');
        this.quill.theme.tooltip.hide();
    }

    getEditorConfig() {
        if (this.formats) {
            this.editorOptions.formats = this.formats;
        }

        if (this.editorOptions.modules) {
            if (this.toolbar) {
                this.editorOptions.modules.toolbar = this.toolbar;
            }

            if (this.autoLinks) {
                this.editorOptions.modules.keyboard.bindings = {
                    spaceToLink: {
                        key: ' ',
                        collapsed: true,
                        prefix: REG_EXP.URL,
                        handler: this.rangeToLink()
                    },
                    enterToLink: {
                        key: 13,
                        collapsed: true,
                        prefix: REG_EXP.URL,
                        handler: this.rangeToLink()
                    }
                };
            }
        }

        return this.editorOptions;
    }

    focus() {
        this.quill?.focus();
    }

    rangeToLink() {
        let lastOffset = 0;

        return (range: RangeStatic) => {
            if (this.quill) {
                const text = this.quill.getText(lastOffset, range.index);
                const match = text.match(RegExp(REG_EXP.URL, 'g'));

                if (match) {
                    let link;
                    if (match.length > 1) {
                        link = match[match.length - 1];
                    } else {
                        [link] = match;
                    }

                    const delta = new Delta();

                    delta.retain(range.index - link.length);
                    delta.delete(link.length);
                    delta.insert(link, { link });

                    this.quill.updateContents(delta as unknown as DeltaStatic);

                    this.syncContent(this.getHtml());

                    lastOffset = 0;
                } else {
                    lastOffset = range.index;
                }
            }

            return true;
        };
    }

    syncContent(content: string) {
        if (this.content !== content) {
            this.content = content;
            this.$emit('input', this.content);
        }
    }
}
</script>

<style>
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
</style>

<style lang="scss" scoped>
.content-editor::v-deep {
    background-color: $main-background;
    border-radius: 4px 4px 0 0;
    position: relative;

    .ql-container {
        .ql-editor {
            min-height: 20em;
            font-size: 18px;
            font-weight: 400;
            color: $secondary-color;
            line-height: 28.8px;
            overflow-y: visible;
            border-bottom: 2px solid $mercury-solid;

            p {
                margin-bottom: 1em;

                .first-person-error {
                    color: white !important;
                    padding: 0 4px;
                    border-radius: 4px;
                    background-color: $primary-color;
                }
            }

            &:after {
                bottom: -1px;
                content: '';
                left: 0;
                position: absolute;
                transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
                width: 100%;
                background-color: $secondary-color-light;
                border-color: $secondary-color-light;
                border-style: solid;
                border-width: thin 0;
                transform: scaleX(0);
                height: 1px;
            }

            &:focus {
                border-bottom: unset !important;

                &:after {
                    transform: scaleX(1);
                    background-color: $secondary-color-light;
                    border-color: $secondary-color-light;
                }
            }

            &.ql-blank::before {
                color: $secondary-color-light;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
            }
        }

        .ql-tooltip {
            width: auto !important;
            z-index: 1000;

            .ql-tooltip-editor {
                width: 350px !important;
            }
        }

        .ql-clipboard {
            position: fixed;
        }

        &.ql-bubble:not(.ql-disabled) a {
            white-space: pre-wrap;
        }

        &.ql-bubble:not(.ql-disabled) a::before,
        &.ql-bubble:not(.ql-disabled) a::after {
            z-index: 1000 !important;
            max-width: 640px !important;
        }

        &.ql-bubble:not(.ql-disabled) a:hover::before,
        &.ql-bubble:not(.ql-disabled) a:hover::after {
            visibility: var(--qlLinkPreviewVisibility, 'visible');
        }
    }

    &.has-error {
        .ql-container {
            .ql-editor {
                &:after {
                    transform: scaleX(1);
                    background-color: $error;
                    border-color: $error;
                }
            }
        }
    }

    &.content-editor-short {
        .ql-container {
            .ql-editor {
                min-height: 10em;
            }
        }
    }

    &.content-editor-one-line {
        .ql-container {
            .ql-editor {
                min-height: 1em;
            }
        }
    }
}

@media all and (max-width: 960px) {
    .ql-container.ql-bubble:not(.ql-disabled) a::before,
    .ql-container.ql-bubble:not(.ql-disabled) a::after {
        z-index: 1000;
        max-width: 290px !important;
        margin-left: 105%;
    }
}
</style>
